<template>
  <div v-if="currentStep" class="z-stepper">
    <div class="container d-flex align-items-center justify-content-around">
      <div
        :class="isStepActiveOrCurrent(1)"
        @click="nav({ name: type ? 'Order' : 'ProductTypeSelect', params: { type: type } }, 1)"
      >Prihláška</div>
      <div
        :class="{ ...isStepActiveOrCurrent(2), 'not-clickable': isLoggedIn }"
        @click="nav({name: 'Registration'}, 2)"
      >Registrácia / Prihlásenie</div>
      <div
        :class="{ ...isStepActiveOrCurrent(3), 'not-clickable': $route.name == 'Acknowledgment' }"
        @click="nav({name: 'StudentsInformation'}, 3)"
      >Údaje o študentoch</div>
      <div
        :class="isStepActiveOrCurrent(4)"
        @click="nav({name: 'Summary'}, 4)"
      >Dokončenie</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('wAuth', ['isLoggedIn']),
    ...mapGetters('order', ['orderProductType']),
    
    currentStep() {
      return this.$route.meta.step
    },

    type() {
      return this.orderProductType || this.$route.query.type
    }
  },

  methods: {
    nav(navTo, destinationStep) {
      if (this.currentStep <= destinationStep) return
      this.$router.push({...navTo })
    },

    isStepActiveOrCurrent(step) {
      return { 'active': step < this.currentStep, 'current': this.currentStep == step }
    }
  },
}
</script>

<style lang="sass" scoped>
.z-stepper
  position: fixed
  top: 62px
  right: 0
  left: 0
  z-index: 1030
  height: 62px
  border-top: 3px solid transparent
  background-color: #F2F0EA

  & > div
    height: 100%

  & > div > div
    display: flex
    align-items: center
    justify-content: center
    flex-grow: 1
    flex-basis: 0
    height: 100%
    border-bottom: 3px solid #fff

    &::after
      content: ""
      height: 12px
      width: 12px
      position: absolute
      background: white
      transform: translateY(30.55px)
      border-radius: 50%
      border: 1px solid #F2F0EA

    &.active
      border-bottom: 3px solid #28a745
      cursor: pointer

      &::after
        background: #28a745

    &.current
      border-bottom: 3px solid #F7BE3D
      font-weight: 700
      font-size: 18px

      &::after
        background: #F7BE3D
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)

.not-clickable
  pointer-events: none
</style>